<template>
	<div v-if="!this.is_geo"
		 :class="{
			'row row-cols-1': this.section == 'requisitions',
			'row-fluid row-cols-1': this.section !== 'requisitions'
		}">
		<div v-if="!this.inArray(this.section, ['autosearch', 'clients', 'requisitions'])" class="container px-20">
			<div class="row row-cols-2 mb-3 align-items-center">
				<div v-show="this.total !== 0" class="col-4 col-md-6 me-auto text-font-secondary">
					Всего: <span class="text-font-primary" :data-total-count="this.total" :data-selected-count="this.selectedItems.length" v-text="(this.total) ? this.total : 0" />
					<span v-if="!this.isEmpty(this.selectedItems)" class="text-font-secondary"> [{{ this.selectedItems.length }}]</span>
				</div>
				<div v-show="this.total > 0 && this.$props.layout !== 'search' && this.$props.layout !== 'short'" class="ms-auto col-8 d-flex align-items-center">
					<span class="ms-auto d-none d-sm-block me-3 text-font-secondary">Сортировка:</span>
					<InputSelect inputId="sort_order"
								 inputName="sort_order"
								 :inputOptions="this.sortOrderList"
								 :inputValue="this.sort_order"
								 inputWrapClass="ms-auto me-0"
								 inputPlaceholder="По умолчанию"
								 inputClass="rounded-1 py-10"
								 inputLabelClass="text-font-secondary mb-3"
								 @setValue="(value) => {
									this.sort_order = value;
								}" />
				</div>
			</div>
		</div>
		<div v-if="this.is_loading_process" class="container my-auto px-20 d-flex flex-column align-items-center">
			<Loader />
		</div>
		<div v-else-if="!this.isEmpty(this.objectsItems)" class="container px-20">
			<ObjectCard v-for="(object, index) of this.objectsItems"
						:id="'objectItem_' + object.id"
						:ref="'objectItem_' + object.id"
						:key="index"
						:objectItem="object"
						:tagsList="this.tags_list"
						:inputFilters="this.filters"
						:section="this.section ?? 'objects'"
						:asfilterId="this.asfilter_id"
						:layout="this.$props.layout ?? 'default'"
						:isChecked="this.inArray(object.id, this.selectedItems)"
						:isFavoured="this.inArray(object.id, this.favoured)"
						:isViewed="this.inArray(object.id, this.viewed)"
						:isLoadingProcess="this.is_loading_process"
						:isUpdatingProcess="(this.is_updating_process || this.is_loading_process)"
						:isReverseProcess="this.is_reverse_process"
						@objectChecked="(object_id) => this.collectCheckedList(object_id)"
						@updateObjectsList="this.updateObjectsList()" 
						@setObjectAdvertState="data => this.setObjectAdvertState(data)"
						@updateObject="(object_id) => this.updateObject(object_id, 'card')"
						@objectChanged="this.getObjectsList()"
						@addRemoveObjectFavour="(object_id) => this.emit('addRemoveObjectFavour', object_id)"
						@addObjectToIgnored="(object_id) => this.emit('addObjectToIgnored', object_id)"
						@addRemoveObjectArchive="(object_id) => this.emit('addRemoveObjectArchive', object_id)"
						@addRemoveObjectViewed="(object_id) => this.emit('addRemoveObjectViewed', object_id)" 
						@updateCounteres="this.updateCounteres()"/>
		</div>
		<div v-else-if="!this.is_loading_process" class="container px-20 d-flex flex-column align-items-center">
			<EmptyList message="Объекты не найдены" />
		</div>
	</div>
	<div v-if="!this.isEmpty(this.objectsItems)">
		<ObjectOnMapModal v-if="this.section !== 'autosearch'" />
		<TarifsEgrnModal v-if="this.section !== 'autosearch'" />
		<OrderEgrnModal v-if="this.section !== 'autosearch'" />
		<ListEgrnModal v-if="this.section !== 'autosearch'" />
		<AdvertStatsModal v-if="this.section !== 'autosearch'" />
		<AdvertCoastModal v-if="this.section !== 'autosearch'" />
		<ArchiveObjectModal @updateObjectsList="this.loadObjects" />

		<AdvertDaysModal v-if="this.section !== 'autosearch'"
						 @updateObject="(object_id) => this.updateObject(object_id, 'adverts')"
						 @setObjectAdvertState="data => this.setObjectAdvertState(data)"
						 :isModeration="this.is_adverts_moderation" />

		<AdvertPromotionModal v-if="this.section !== 'autosearch'"
						 @updateObject="(object_id) => this.updateObject(object_id, 'adverts')"
						 @setObjectAdvertState="data => this.setObjectAdvertState(data)"
						 :isModeration="this.is_adverts_moderation"
						 :inputFilters="this.filters" />



		<HistoryTasksModal v-if="this.section !== 'autosearch'" fromSection="objects" @updateObject="(object_id) => this.updateObject(object_id, 'history')" />
		<SetTagsModal v-if="this.section !== 'autosearch'" @saveSuccess="(tags_ids, source_id) => this.setTags(tags_ids, source_id)" />
		<ObjectsAnalyticsModal v-if="this.section !== 'autosearch'" />
		<AutoSearchReqModal v-if="this.section !== 'autosearch'" />
	</div>
</template>

<script>
	import EmptyList from "@/components/common/EmptyList";
import Loader from "@/components/common/Loader";
import InputSelect from "@/components/inputs/InputSelect";
import AdvertDaysModal from "@/components/modals/AdvertDaysModal";
import AdvertPromotionModal from "@/components/modals/AdvertPromotionModal";
import ArchiveObjectModal from "@/components/modals/ArchiveObjectModal";
import AutoSearchReqModal from "@/components/modals/AutoSearchReqModal";
import HistoryTasksModal from "@/components/modals/HistoryTasksModal";
import ListEgrnModal from "@/components/modals/ListEgrnModal";
import ObjectOnMapModal from "@/components/modals/ObjectOnMapModal";
import ObjectsAnalyticsModal from "@/components/modals/ObjectsAnalyticsModal";
import SetTagsModal from "@/components/modals/SetTagsModal";
import ObjectCard from "@/components/objects/ObjectCard";
import CommonService from "@/services/CommonService";
import api from '../../api';
import AdvertCoastModal from '../modals/AdvertCoastModal.vue';
import AdvertStatsModal from '../modals/AdvertStatsModal.vue';
import OrderEgrnModal from '../modals/OrderEgrnModal.vue';
import TarifsEgrnModal from '../modals/TarifsEgrnModal.vue';

    export default {
		props: {
			isAllChecked: {type: Boolean},
			isGeo: {type: Boolean},
			isGlobalSearch: {type: Boolean},
			layout: { type: String, default: 'default' },
			currentPage: {type: Number},
			perPage: {type: Number},
			currentSection: {type: String},
			inputFilters: {type: [Array, Object]},
			favouredList: {type: Array},
			viewedList: {type: Array},
			tagsList: {type: Array},
			searchQuery: {type: String},
			asfilterId: { type: [String, Number] },
		},
		components: {
      ArchiveObjectModal,
			AutoSearchReqModal,
			ObjectCard,
			AdvertDaysModal,
			EmptyList,
			HistoryTasksModal,
			ListEgrnModal,
			InputSelect,
			ObjectsAnalyticsModal,
			Loader,
			OrderEgrnModal,
			TarifsEgrnModal,
			AdvertStatsModal,
			AdvertCoastModal,
			ObjectOnMapModal,
			SetTagsModal,
			AdvertPromotionModal
		},
        data() {
            return {
				objectsIds: [],
				objectsItems: [],
				reverseSearch: [],
				selectedItems: [],
				is_loading_process: true,
				is_updating_process: false,
				is_reverse_process: true,
				page: 1,
				per_page: 10,
				sortOrderList: [
					{
						value: 0,
						name: 'Дата изменения (от нового к старому)',
					},
					{
						value: 1,
						name: 'Дата изменения (от старого к новому)',
					},
					{
						value: 2,
						name: 'Дата добавления (от нового к старому)',
					},
					{
						value: 3,
						name: 'Дата добавления (от старого к новому)',
					},
				],
				sort_order: 0,
				total: 0,
			}
        },
		methods: {
			arrayIsDiff(data, data2) {
				return (CommonService.arrayDiff(data, data2).length > 0);
			},

			emit(event_name, payload) {
				if (event_name == 'addObjectToIgnored' && this.section == 'autosearch') {
					this.objectsItems.forEach((item, index, object) => {
						if (item.id == payload) {
							object.splice(index, 1);
						}
					});
				}
				this.$emit(event_name, payload);
			},

			removeObjectItem: () => {
				//this.$refs['objectItem_' + object_id].remove();
				console.log(this.$refs);
			},
			updateCounteres: function(filters,search_query, section){
				var _this = this;
				
				if (!section)
					section = 'active';

				if (!filters)
					filters = {};

				if (!search_query)
					search_query = null;

				if (filters['with_geo'] === true)
					_this.is_geo = true;
				else
					_this.is_geo = false;

				let counters = {};
				let pagination = {};
				this.getObjectsCounters(section, filters, search_query).then((data) => {
					counters = data;
					_this.$emit('objectsLoaded', {
					pagination: (!_this.is_geo) ? pagination : null,
					counters: counters, // Пробрасываем счётчики родителю для построения меню/навигации
					});
				});
			},
			updateObject: function (object_id, from) {

				console.info({object_id, from});

				if (!this.isEmpty(object_id) && this.inArray(from, ['card', 'adverts', 'history'])) {

					this.emitter.emit("global.startGetObject", { ref: 'ObjectsList::updateObject()', object_id, from });

					api.get('/object', {
						params: {
							object_id: object_id,
							section: from,
						}
					}).then((response) => {
						CommonService.log('debug', 'updateObject()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {

							let new_object = response.data.item;
							Object.entries(this.objectsItems).forEach(([, object]) => {
								if (object.id == object_id) {

									let use_in_advert = new_object.use_in_advert || [];
									if (use_in_advert)
										object.use_in_advert = use_in_advert;
									else
										object.use_in_advert = false;

									let services = new_object.services || [];
									if (services)
										object.services = services;
									else
										object.services = {};

                  let prices = new_object.prices || [];
                  if (prices)
                    object.prices = prices;
                  else
                    object.prices = {};

									if (!this.isEmpty(new_object.moderation.advertised)) {
										let moderation = new_object.moderation || {};
										if (!this.isEmpty(moderation.advertised) && (moderation.status == 'not_send_yet' || moderation.status == 'pending')) {

											/*object.services.advertised = {
												...moderation.advertised,
												...object.services.advertised,
											};*/

											if (moderation.advertised.packages == true)
												object.services.advertised.packages = true;

											if (moderation.advertised.free == true)
												object.services.advertised.free = true;

											if (moderation.advertised.avito == true)
												object.services.advertised.avito = true;

											if (moderation.advertised.yandex == true)
												object.services.advertised.yandex = true;

											if (moderation.advertised.cian == true)
												object.services.advertised.cian = true;

											if (moderation.advertised.domclick == true)
												object.services.advertised.domclick = true;

											if (moderation.advertised.zipal == true)
												object.services.advertised.zipal = true;

											if (moderation.advertised.jcat == true)
												object.services.advertised.jcat = true;


											if (from == 'card') {
												object.moderation = moderation;
											}
										}
									} else {
										object.moderation = null;
									}

									let notes_count = new_object.notes_count || false;
									if (notes_count)
										object.notes_count = notes_count;
									else
										object.notes_count = null;

								}
							});
						}

						this.emitter.emit("global.endGetObject", { response: response });
					}).catch(function (error) {

						CommonService.log('error', 'updateObject()::axios', error);
						this.emitter.emit("global.endGetObject", { error: error });

					});
				} else {

					CommonService.log('error', 'updateObject()::object_id', 'Prop `object_id` is empty!');

				}
			},
			
			updateObjectsList: function () {
				this.$emit('updateObjectsList', this.page);
			},
			setObjectAdvertState: function (data) {

				CommonService.log('debug', 'setObjectAdvertState()', {section: this.section, objectsItems: this.objectsItems});

				if (data.object_id && this.objectsItems.length > 0) {
					[].forEach.call(this.objectsItems, function (item) {
						if (item.id == data.object_id) {
							item.services.advertised[data.service] = data.state;
						}
					});
				}

				if (this.use_advert_budget) {
					this.emitter.emit("updateAdvertsBudget");
				}

			},
			getObjectsList: function () {

				CommonService.log('debug', 'getObjectsList()', {section: this.section, objectsItems: this.objectsItems});

				if (this.$props.layout !== 'search') {
					this.objectsItems = [];

					let is_post = false;
					if (this.section == "autosearch")
						is_post = true;

					this.loadObjects(this.currentPage, this.perPage, this.section, this.filters, this.search_query, is_post);
				} else {
					this.$emit('applyFilter', this.filters);
				}
			},
			getReverseSearch: function (objects_ids) {

				let params = {
					section: 'counters',
					objects_ids: objects_ids
				};

				this.is_reverse_process = true;

				CommonService.log('debug', 'getReverseSearch()', params);

				return api.get('/autosearch/reverse', {
					params: params
				})
				.then((response) => {

					CommonService.log('debug', 'getReverseSearch()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let results = [];
						if (response.data.results)
							results = response.data.results;

						return results;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getReverseSearch()::axios', error);

				});
			},
			collectCheckedList: function (object_id) {

				if (this.selectedItems.includes(object_id)) {
					let index = this.selectedItems.indexOf(object_id);
					if (index !== -1) {
						this.selectedItems.splice(index, 1);
					}
				} else {
					this.selectedItems.push(object_id);
				}

				this.$emit('selectedItems', this.selectedItems);

				CommonService.log('debug', 'collectCheckedList', this.selectedItems);

			},
			setTags: function (tags, object_id) {

				let new_tags = [];
				if (typeof tags != "undefined" && this.objectsItems.length > 0) {

					[].forEach.call(this.tags_list, function (tag) {
						if (tags.indexOf(tag.id) !== -1)
							new_tags.push(tag.id);
					});

					[].forEach.call(this.objectsItems, function (item) {
						if (item.id == object_id) {
							item.tags = new_tags;
						}
					});

					console.log(this.objectsItems);
				}

				CommonService.log('debug', 'setTags', {tags: tags, new_tags: new_tags, object_id: object_id});

				this.$emit('updateObjects', object_id);
			},
			getTagsList(tags) {

				let ids = [];
				let tagsList = this.tags_list;
				if (tagsList && typeof tagsList !== "undefined") {

					if (tags && typeof tags !== "undefined") {
						Object.entries(tags).forEach(([, tag]) => {
							ids.push(tag.id);
						});
					}

					Object.entries(tagsList).forEach(([, tag]) => {
						if (ids.indexOf(tag.id) !== -1)
							tag.isChecked = true;
						else
							tag.isChecked = false;
					});
				}
				return tagsList;
			},
			getObjectsCounters: async function (section, filters, search_query) {
				return api.get('/objects/counters', {
					params: {
						section: section,
						filters: filters,
						search: search_query || null,
					}
				}).then((response) => {

					CommonService.log('debug', 'getObjectsCounters()::axios', {response: response.data});

					if (response.status == 200) {
						return {
							total: response.data.total,
							sale: response.data.sale,
							rent: response.data.rent,
							active: response.data.active,
							potential: response.data.potential,
							archive: response.data.archive,
							favoured: response.data.favoured,
							adverts: response.data.adverts,
							not_in_adverts: response.data.not_in_adverts,
							is_moderated: response.data.is_moderated,
							not_moderated: response.data.not_moderated,
						}
					}

					return {};
				}).catch(function (error) {

					CommonService.log('error', 'getObjectsCounters()::axios', error);

				});
			},
			loadObjects: async function (page, per_page, section, filters, search_query, use_post) {

				let _this = this;
				_this.objectsItems = [];
				_this.total = 0;
				_this.is_loading_process = true;

				if (typeof page == "undefined")
					page = 1;

				if (!section)
					section = 'active';

				if (!filters)
					filters = {};

				if (!search_query)
					search_query = null;

				if (filters['with_geo'] === true)
					_this.is_geo = true;
				else
					_this.is_geo = false;

				let counters = {};
				let pagination = {};
				this.getObjectsCounters(section, filters, search_query).then((data) => {

					counters = data
					if (!_this.isEmpty(counters)) {
						pagination = {
							total: counters.total,
							current: page,
							pages: Math.ceil((counters.total / (parseInt(per_page) || 10))),
							items: [...Array(counters.total).keys()].map(i => ({
								id: (i + 1),
								name: 'Item ' + (i + 1)
							})),
							per_page: per_page,
						};
					}

					if (!_this.isEmpty(counters))
						_this.total = counters.total;
					else
						_this.total = 0;

          _this.$emit('objectsLoaded', {
            pagination: (!_this.is_geo) ? pagination : null,
            counters: counters, // Пробрасываем счётчики родителю для построения меню/навигации
          });

					_this.loadObjectsAfterCount(page, per_page, section, filters, search_query, use_post, _this, pagination, counters);
				});
			},
      loadObjectsAfterCount(page, per_page, section, filters, search_query, use_post, _this, pagination, counters) {
        let fetch = null;
        if (use_post === true) {
          fetch = api.post('/objects?sort_order=' + _this.sort_order, {
            page: (typeof page != "undefined") ? page : 1,
            per_page: (typeof per_page != "undefined") ? per_page : 10,
            section: section, // секция выборки/поиска объектов (Глобальный поиск, Аренда, Продажа, Избранное etc.)
            filters: filters, // фильтры выборки (параметры поиска)
            search: search_query, // текстовый запрос поиска
          });
        } else {
          fetch = api.get('/objects', {
            params: {
              sort_order: _this.sort_order,
              page: (typeof page != "undefined") ? page : 1,
              per_page: (typeof per_page != "undefined") ? per_page : 10,
              section: section, // секция выборки/поиска объектов (Глобальный поиск, Аренда, Продажа, Избранное etc.)
              filters: filters, // фильтры выборки (параметры поиска)
              search: search_query, // текстовый запрос поиска
            }
          });
        }

        fetch.then((response) => {

          CommonService.log('debug', 'loadObjects()::axios', {response: response.data});

          if (response.status === 200) {


            if (response.data.success) {
              _this.page = response.data.page;
              _this.per_page = response.data.per_page;
              _this.section = response.data.section;
              _this.objectsItems = response.data.objects;
              _this.objectsIds = response.data.ids;

              let with_geo = false;
              if (!_this.isEmpty(response.data.filters)) {
                if ('with_geo' in response.data.filters)
                  with_geo = response.data.filters.with_geo;
              }

              /*if (!_this.isEmpty(response.data.tags_list)) {
                let tags_list = [];
                Object.entries(response.data.tags_list).forEach(([key, tag]) => {
                  tag.isChecked = false;
                  tags_list.push(tag);
                });
                _this.tags_list = tags_list;
              }*/

              if (response.data.ids.length && !with_geo && _this.section !== 'autosearch') {
                _this.getReverseSearch(response.data.ids).then(data => {
                  _this.reverseSearch = data;
                  Object.entries(_this.objectsItems).forEach(([, object]) => {
                    if (typeof _this.reverseSearch[object.id] == 'object') {
                      if ('total' in _this.reverseSearch[object.id]) {
                        let total = _this.reverseSearch[object.id]['total'] || false;
												let has_items = _this.reverseSearch[object.id] || false;
                        if (has_items.has_items === true)
                          object.autosearch = {count: total || 0, has_items: true};
                        else
                          object.autosearch = null;
                      }
                    }
                  });

                  this.is_reverse_process = false;
                });
              }

              if (_this.section == 'autosearch') {

                Object.entries(_this.objectsItems).forEach(([, object]) => {

                  if (!_this.isEmpty(_this.viewed)) {
                    if (_this.inArray(object.id, _this.viewed)) {
                      object.is_viewed = true;
                    } else {
                      object.is_viewed = false;
                    }
                  }
                });
              }
              _this.is_loading_process = false;
            } else {
              _this.objectsItems = [];
              _this.is_loading_process = false;
            }

            CommonService.log('log', 'objectsLoaded()::paginationpaginationpagination', pagination);

            _this.$emit('objectsLoaded', {
              ids: _this.objectsIds,
              objects: _this.objectsItems,
              pagination: (!_this.is_geo) ? pagination : null,
              counters: counters, // Пробрасываем счётчики родителю для построения меню/навигации
            });

            _this.is_loading_process = false;
          }
        }).catch(function (error) {

          CommonService.log('error', 'loadObjects()::axios', error);

          _this.is_loading_process = false;
        });
      },
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			inArray(needle, haystack) {
				return CommonService.inArray(needle, haystack);
			},
			resetData() {

				//this.tags_list = [];
				this.objectsIds = [];
				this.objectsItems = [];
				this.reverseSearch = [];
				this.selectedItems = [];

				CommonService.log('debug', 'resetData()', this);

			},
		},
		mounted() {
			if (typeof this.currentPage !== "undefined" && this.currentPage) {
				this.page = this.currentPage;
			}
			if (typeof this.perPage !== "undefined" && this.perPage) {
				this.per_page = this.perPage
			}

			let is_post = false;
			if (this.section == "autosearch")
				is_post = true;

			if (!this.is_global && this.section != "autosearch") {
				this.loadObjects(this.page, this.per_page, this.section, this.filters, this.search_query, is_post);
			} else if (this.section == "autosearch") {
				if (!this.isEmpty(this.filters.asfilter_id) && !this.isEmpty(this.filters.objects_ids)) {
					this.loadObjects(this.page, this.per_page, this.section, this.filters, this.search_query, is_post);
				} else {
					this.objectsItems = null;
					CommonService.debounce(() => this.is_loading_process = false, 10000);
				}

			}

			if (this.layout === 'search') {
				this.sortOrderList.push(
					{
						value: 4,
						name: 'Дешевле',
					},
					{
						value: 5,
						name: 'Дороже',
					},
				)
			}

			this.emitter.on("updateObjects", () => {
				this.loadObjects(this.page, this.per_page, this.section, this.filters, this.search_query, is_post);
			});

			this.emitter.on('updateObjectPrice', (object_id) => {
				this.updateObject(object_id, 'card')
			});

			this.emitter.on('global.startGetObject', () => {
				this.is_updating_process = true;
			});

			this.emitter.on('global.endGetObject', () => {
				this.is_updating_process = false;
			});

		},
		watch: {
			'filters': function(val, oldVal) {
				CommonService.log('debug', 'watch:filters', {value: val, old_value: oldVal});

				if (this.section == "autosearch") {
					if (!this.isEmpty(this.filters.asfilter_id) && !this.isEmpty(this.filters.objects_ids)) {
						this.loadObjects(this.page, this.per_page, this.section, this.filters, this.search_query, true);
					} else {
						this.objectsItems = null;
						this.is_loading_process = false;
					}
				}
			},
			'sort_order': function() {
				console.log(this.section, 'wqatcherwatcher');
				this.loadObjects(this.page, this.per_page, this.section, this.filters, this.search_query);
			},
			'is_geo': function(val, oldVal) {
				CommonService.log('debug', 'ObjectsList::watch()::is_geo', {value: val, old_value: oldVal});
			},
			'is_global': function(val, oldVal) {
				CommonService.log('debug', 'ObjectsList::watch()::is_global', {value: val, old_value: oldVal});
			},
			'section': function(val, oldVal) {
				CommonService.log('debug', 'ObjectsList::watch()::section', {value: val, old_value: oldVal});
			},
			'is_adverts_moderation': function(val, oldVal) {
				CommonService.log('debug', 'ObjectsList::watch()::is_adverts_moderation', {value: val, old_value: oldVal});
			},
			'selectedItems': function(val, oldVal) {
				CommonService.log('debug', 'ObjectsList::watch()::selectedItems', {value: val, old_value: oldVal});
			},
			'viewed': function(val, oldVal) {
				CommonService.log('debug', 'ObjectsList::watch()::viewed', {value: val, old_value: oldVal});
			},
			'objectsItems': function(val, oldVal) {
				CommonService.log('debug', 'ObjectsList::watch()::objectsItems', {value: val, old_value: oldVal});
			},
		},
		computed: {
			is_geo() {
				return (typeof this.isGeo !== "undefined" && this.isGeo) ? true : false;
			},
			is_global() {
				return (typeof this.isGlobalSearch !== "undefined" && this.isGlobalSearch) ? true : false;
			},
			section() {
				let section = (typeof this.$props.currentSection !== "undefined") ? this.$props.currentSection : '';
				CommonService.log('debug', 'computed::section', section);
				return section;
			},
			is_adverts_moderation() {
				return (this.section == 'adverts' && this.$store.getters.userInfo.user_can.add_to_moderation) ? true : false;
			},
			user() {
				return this.$store.getters.userInfo;
			},
			is_agency() {
				return this.user.is_agency;
			},
			use_advert_budget() {
				return this.user.services.use_advert_budget;
			},
			favoured() {
				let favoured = (typeof this.$props.favouredList !== "undefined") ? this.$props.favouredList : [];
				CommonService.log('debug', 'computed::favoured', favoured);
				return favoured;
			},
			viewed() {
				let viewed = (typeof this.$props.viewedList !== "undefined") ? this.$props.viewedList : [];
				CommonService.log('debug', 'computed::viewed', viewed);
				return viewed;
			},
			tags_list() {
				let viewed = (typeof this.$props.tagsList !== "undefined") ? this.$props.tagsList : [];
				CommonService.log('debug', 'computed::tags_list', viewed);
				return viewed;
			},
			filters() {
				let filters = (typeof this.$props.inputFilters !== "undefined") ? this.$props.inputFilters : [];
				CommonService.log('debug', 'computed::filters', filters);
				return filters;
			},
			search_query() {
				let search_query = (typeof this.$props.searchQuery !== "undefined") ? this.$props.searchQuery : '';
				CommonService.log('debug', 'computed::search_query', search_query);
				return search_query;
			},
			asfilter_id() {
				let asfilter_id = this.$props.asfilterId ?? null;
				CommonService.log('debug', 'computed::asfilter_id', asfilter_id);
				return asfilter_id;
			},
		},
    };
</script>
